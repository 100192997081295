import { FormikCheckBoxField, FormikSelectField } from "@components/form";
import { Col, Page, PrimaryButton, Row, StyledParagraph, WidthContext } from "@components/general";
import Logo from "@components/general/Logo";
import config from "@config/index";
import { Grid, MenuItem } from "@material-ui/core";
import { acquireVisitorId } from "@reducers/user";
import * as ROUTE from "@resources/routeConst";
import { color } from "@resources/styles";
import { getCookie, VISITOR_ID } from "@utils/cookie";
import { useTranslation } from "@utils/i18n";
import isServer from "@utils/isServer";
import NextFCPage from "@utils/types";
import { findPageNameByPath } from "@utils/utils";
import { FormikProvider, useFormik } from "formik";
import _ from "lodash";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";

const StyledCard = styled(Grid) <{ $mobile: boolean }>`
  position: relative;
  width: ${(props) => (props.$mobile ? "100%" : "600px")};
  background-color: ${color.realWhite};
  border-radius: 5px;
  box-shadow: ${(props) => (props.$mobile ? "none" : "0 10px 20px 0 rgba(0, 0, 0, 0.1)")};
`;

const AgeVerificationSchema = Yup.object().shape({
  rememberMe: Yup.boolean().nullable().notRequired(),
  province: config.drup ? Yup.string().required() : Yup.string().notRequired(),
});

type AgeVerificationFormType = Yup.InferType<typeof AgeVerificationSchema>;

const AgeVerification: NextFCPage = () => {
  const width = useContext(WidthContext);
  const mobile = width === "xs";
  const [isDenied, setIsDenied] = useState(false);
  const { tOrNull: t, getObject } = useTranslation(["age-verification"]);
  const router = useRouter();
  const returnUrl = (router.query?.returnUrl as string) || "/";
  const provinceOptions = getObject("form.province.options") || [];

  if (!isServer && getCookie(VISITOR_ID) && !returnUrl.includes("age-verification")) {
    // redirect({ location: returnUrl });
    const pageName = findPageNameByPath(returnUrl);
    const queryParams = returnUrl.split("?")[1];
    if (pageName) {
      router.replace(`${pageName}?${queryParams}`, returnUrl);
    } else {
      router.replace(returnUrl);
    }
  }

  const initialValues: AgeVerificationFormType = {
    rememberMe: false,
    province: config.drup ? "ON" : undefined
  };

  const handleSubmit = async (values) => {
    await acquireVisitorId(values.rememberMe);
    const pageName = findPageNameByPath(returnUrl);
    const queryParams = returnUrl.split("?")[1];

    if (!isServer) {
      // save province value to local storage for product query to use as a filter
      window.localStorage.setItem("province", values.province);
    }

    if (pageName) {
      router.replace(`${pageName}?${queryParams}`, returnUrl);
    } else {
      router.replace(returnUrl);
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: AgeVerificationSchema,
    onSubmit: handleSubmit
  });

  return (
    <Page showHeader={false} showFooter={false}>
      <div
        style={{
          paddingTop: mobile ? 0 : 60,
          paddingBottom: mobile ? 0 : 160,
          minHeight: "100vh",
          background: mobile ? color.realWhite : color.blueGradientFroAgeVerification,
        }}
      >
        <Grid container justify="center">
          <Grid item>
            <StyledCard container $mobile={mobile}>
              <Row style={{ marginTop: 40, marginBottom: 15 }} item justify="center">
                <Logo mini={true} />
              </Row>
              <Row justify="center">
                <Grid item xs={11}>
                  <StyledParagraph guideline="h2" color={color.blue} align="center" fade="bottom">
                    {isDenied ? t("denied.title") : t("title")}
                  </StyledParagraph>
                </Grid>
              </Row>

              <Row justify="center" item style={{ marginBottom: 46 }}>
                <Grid item xs={10} sm={8}>
                  {isDenied ? (
                    <Row justify="center">
                      <StyledParagraph guideline="p1" color={color.black} style={{ textAlign: "center" }}>
                        {t("denied.description")}
                      </StyledParagraph>
                    </Row>
                  ) : (
                    <>
                      <FormikProvider value={formik}>
                        <form onSubmit={formik.handleSubmit}>
                          <Row justify="center">
                            <StyledParagraph guideline="p1" color={color.black} align="center" fade="bottom">
                              {t("form.age.label")}
                            </StyledParagraph>
                          </Row>
                          <Row justify="center" spacing={2}>
                            <Grid item xs={6}>
                              <PrimaryButton type="submit" fullWidth loading={formik.isSubmitting}>
                                {t("form.age.yes")}
                              </PrimaryButton>
                            </Grid>
                            <Grid item xs={6}>
                              <PrimaryButton secondary fullWidth onClick={() => setIsDenied(true)}>
                                {t("form.age.no")}
                              </PrimaryButton>
                            </Grid>
                          </Row>

                          {
                            config.drup && <>
                              <Row justify="center">
                                <StyledParagraph guideline="p1" color={color.black} fade="bottom">
                                  {t("form.province.label")}
                                </StyledParagraph>
                              </Row>

                              {
                                !_.isEmpty(provinceOptions) && <Row justify="center">
                                  <Grid item xs={10}>
                                    <FormikSelectField
                                      type="text"
                                      name="province"
                                      placeholder={"Province"}
                                    >
                                      {Object.keys(provinceOptions).map((k) => (
                                        <MenuItem key={k} value={k}>
                                          {provinceOptions[k]}
                                        </MenuItem>
                                      ))}
                                    </FormikSelectField>
                                  </Grid>
                                </Row>
                              }
                            </>
                          }

                          <FormikCheckBoxField type="checkbox" name="rememberMe" label={t("form.checkbox_label")} />
                        </form>
                      </FormikProvider>

                      <StyledParagraph fade="bottom" guideline="p2" align="center" color={color.black}>
                        {t("form.tnc.part1")}
                        <Link href={ROUTE.TERMSANDCONDITIONS}>
                          <a className="link-blue">{t("form.tnc.part2")}</a>
                        </Link>
                        {t("form.tnc.part3")}
                        <Link href={ROUTE.PRIVACYPOLICY}>
                          <a className="link-blue">{t("form.tnc.part4")}</a>
                        </Link>
                      </StyledParagraph>
                    </>
                  )}
                </Grid>
              </Row>
            </StyledCard>
          </Grid>
        </Grid>
      </div>
    </Page>
  );
};

AgeVerification.ageVerification = false;
export default AgeVerification;
